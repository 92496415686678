<template>
    <div style="height: 100%">
      <base-table
        ref="table"
        url="/routerWan/find"
        :toolbar="toolbar"
        :columns="columns"
        border
      ></base-table>
    </div>
  </template>
  <script>
  export default {
    name: 'router-wan-info',
    data() {
      var token = this.$token
      return {
        showDialog: false,
        formEmpty: {},
        toolbar: {
          formItems: [
            { label: '区域', prop: 'region_code', cp: this.$ComparePatterns.Matchs },
            {
              label: '备注',
              prop: 'remark',
              cp: this.$ComparePatterns.Matchs,
            },
          ],
          query: {
            SortFields: JSON.stringify([{ Field: 'create_time', Order: -1 }]),
          },
        },
        columns: [
          { bind: { label: 'ID', prop: 'id' } },
          { bind: { label: '区域', prop: 'region_code' } },
          { bind: { label: '路由器编码', prop: 'router_code' } },
          { bind: { label: '广域网接口', prop: 'wan' } },
          { bind: { label: '广域网接口出口ip', prop: 'wan_ip' } },
          { bind: { label: '创建时间', prop: 'create_time' } },
          { bind: { label: '更新时间', prop: 'update_time' } },
          { bind: { label: '备注', prop: 'remark' } },
        ]
      }
    },
    computed: {
      table() {
        return this.$refs.table
      },
    },
    methods: {
    },
  }
  </script>