<template>
  <div style="height: 100%">
    <base-table
      ref="table"
      url="/user/find"
      :toolbar="toolbar"
      :columns="columns"
    ></base-table>
    <default-drawer
      ref="drawer"
      add-url="/user/add"
      upd-url="/user/update"
      :form-empty="formEmpty"
      :form-items="formItems"
      :rules="rules"
      :fix-post-data="fixPostData"
      :init-value="false"
      send-parameters
      @add-success="addSuccess"
      @upd-success="updSuccess"
    ></default-drawer>
  </div>
</template>
<script>
export default {
  name: 'user-manage',
  data() {
    var token = this.$token
    return {
      showDialog: false,
      formEmpty: {},
      toolbar: {
        formItems: [
          { label: '工号', prop: 'id', cp: this.$ComparePatterns.Matchs },
          {
            label: '名称',
            prop: 'nick_name',
            cp: this.$ComparePatterns.Matchs,
          },
          {
            label: '状态',
            prop: 'disabled',
            cp: this.$ComparePatterns.EQ,
            component: 'form-select',
            bind: {
              width: 100,
              options: [
                { label: '全部', value: null },
                { label: '正常', value: false },
                { label: '禁用', value: true },
              ],
            },
          },
        ],
        query: {
          domain: JSON.stringify({
            Field: 'domain',
            ComparePattern: 1,
            Values: [token.domain],
          }),
          SortFields: JSON.stringify([{ Field: 'cre_time', Order: -1 }]),
        },
      },
      columns: [
        { bind: { label: '工号', prop: 'id' } },
        { bind: { label: '姓名', prop: 'nick_name' } },
        {
          bind: { label: '状态', prop: 'disabled' },
          default: { component: 'user-state', bind: { type: 'switch' } },
        },
        { bind: { label: '手机号', prop: 'phone' } },

        {
          bind: { label: '是否为OA用户', prop: 'is_oau', width: 130 },
          default: {
            component: 'cell-text',
            bind: {
              formatter(scope, defaultVal) {
                return defaultVal==null||defaultVal==0 ? '否' : '是'
              }
            }
          }
        },
        {
          bind: { label: '限定只能在公司范围ip登录', prop: 'restriction_type', width: 130 },
          default: {
            component: 'cell-text',
            bind: {
              formatter(scope, defaultVal) {
                return defaultVal==null||defaultVal==0 ? '不限定' : '限定'
              }
            }
          }
        },
        { bind: { label: '创建时间', prop: 'cre_time', width: 150 } },
        { bind: { label: '备注', prop: 'remarks' } },
        {
          bind: { label: '操作', prop: 'Name', width: 120 },
          header: {
            component: 'cell-button',
            bind: { label: '添加', type: 'primary' },
            on: {
              click: () => {
                var token = this.$token
                if (token) {
                  this.formItems[0].bind.disabled = false
                  this.drawer.beginAddData({
                    disabled: false,
                    domain: token.domain,
                  })
                } else {
                  this.$message('token失效请重新登录')
                }
              },
            },
          },
          default: {
            component: 'cell-buttons',
            bind: {
              buttons: [
                {
                  label: '修改',
                  type: 'text',
                  click: (scope) => {
                    this.formItems[0].bind.disabled = true
                    this.drawer.beginEditData(scope.row)
                  },
                },
                {
                  label: '删除',
                  type: 'text',
                  click: this.delData,
                },
              ],
            },
          },
        },
      ],
      formItems: [
        { label: '工号', prop: 'id', bind: { disabled: false } },
        { label: '姓名', prop: 'nick_name' },
        {
          label: '密码',
          prop: 'pwd',
          bind: { type: 'text', autocomplete: 'off' },
        },
        { label: '手机号', prop: 'phone' },
        {
          label: '是否为OA用户',
          prop: 'is_oau',
          component: 'el-switch'
        },
        {
          label: '限定只能在公司范围ip登录',
          prop: 'restriction_type',
          component: 'el-switch'
        },
        {
          label: '备注',
          prop: 'remarks',
          bind: { type: 'textarea' },
        },
      ],
      rules: {
        id: [
          { required: true, message: '请输入工号', trigger: 'blur' },
          {
            min: 4,
            max: 30,
            message: '长度在 1 到 30 个字符',
            trigger: 'blur',
          },
          {
            validator(rule, value, callback) {
              if (/[0-9a-z]{4,30}/gi.test(value)) {
                callback()
              } else {
                callback(new Error('工号为0-9,a-z的4到30位的字符串'))
              }
            },
            trigger: 'blur',
          },
        ],
        nick_name: [
          { required: true, message: '请输入姓名', trigger: 'blur' },
          {
            min: 1,
            max: 15,
            message: '长度在 1 到 15 个字符',
            trigger: 'blur',
          },
        ],
        pwd: [
          { required: true, message: '请输入密码', trigger: 'blur' },
          {
            min: 1,
            max: 15,
            message: '长度在 1 到 15 个字符',
            trigger: 'blur',
          },
        ],
        phone11: [
          { required: true, message: '请输入手机号', trigger: 'blur' },
          {
            min: 11,
            max: 11,
            message: '手机号为 11 个字符',
            trigger: 'blur',
          },
        ],
      },
    }
  },
  computed: {
    table() {
      return this.$refs.table
    },
    drawer() {
      return this.$refs.drawer
    },
  },
  methods: {
    delData(scope) {
      if (confirm('确定删除?')) {
        this.$post('/user/remove', { parameters: JSON.stringify(scope.row) })
          .then((resp) => {
            if (resp.data.code == 0) {
              this.table.delRow(scope.row)
            } else {
              console.error(resp)
              this.$message(resp.data.msg || '删除失败')
            }
          })
          .catch((error) => {
            // 请求失败处理
            console.error(error)
            this.$message('请求发生错误！')
          })
      }
    },
    fixPostData(data) {
      if (data.pwd == '******') {
        data.pwd = null
      } else if (data.pwd) {
        data.pwd = this.$md5(data.pwd).toUpperCase()
      }
    },
    addSuccess(row) {
      row.pwd = '******'
      this.table.addRow(row)
    },
    updSuccess(oldRow, newRow) {
      oldRow.pwd = '******'
      newRow.pwd = '******'
      this.table.updRow(oldRow, newRow)
    },
  },
}
</script>