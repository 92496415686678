export default [
  {
    text: '域管理',
    icon: 'el-icon-s-claim',
    path: '/DomainManage',
    resid: 'XLNAZVaX2mYZDqyPJgwD'
  },
  {
    text: '项目管理',
    icon: 'el-icon-s-claim',
    path: '/ProjectManage',
    resid: 'YR7xGVyMbzmL7KQmJPlL'
  },
  {
    text: '用户管理',
    icon: 'el-icon-s-claim',
    path: '/UserManage',
    resid: '49zXElpzo8nNRdyeg8jO'
  },
  {
    text: '公司设备信息',
    icon: 'el-icon-s-claim',
    path: '/CompanyDeviceInfo',
    resid: 'ac95e2e325974f7a8e8250a7eccc97cc',
    child: [
      {
        text: '路由器WAN信息',
        icon: 'el-icon-s-claim',
        path: '/RouterWanInfo',
        resid: 'fc9326ebfb7342b6a6d924de71668ab6'
      }
    ]
  }
]
